<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <a-row :gutter="24">
        <a-col :span="4">
          <!-- 机构树 -->
          <dept-tree ref="deptTree" :deptOptions="deptOptions" @select="clickDeptNode" />
        </a-col>
        <a-col :span="20">
          <!-- 条件搜索 -->
          <div class="table-page-search-wrapper">
            <a-form layout="inline">
              <a-row :gutter="48">
                <a-col :md="8" :sm="24">
                  <a-form-item label="图元颜色" prop="primitiveColor">
                    <a-input v-model="queryParam.primitiveColor" placeholder="请输入图元颜色" allow-clear />
                  </a-form-item>
                </a-col>
                <template v-if="advanced">
                  <a-col :md="8" :sm="24">
                    <a-form-item label="是否删除" prop="status">
                      <a-select
                        placeholder="请选择是否删除"
                        v-model="queryParam.status"
                        style="width: 100%"
                        allow-clear
                      >
                        <a-select-option v-for="(d, index) in statusOptions" :key="index" :value="d.dictValue">{{
                          d.dictLabel
                        }}</a-select-option>
                      </a-select>
                    </a-form-item>
                  </a-col>
                </template>
                <a-col :md="(!advanced && 8) || 24" :sm="24">
                  <span
                    class="table-page-search-submitButtons"
                    :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                  >
                    <a-button type="primary" @click="handleQuery" id="tableQueryBtn"><a-icon type="search" />查询</a-button
                    >
                    <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                    <a @click="toggleAdvanced" style="margin-left: 8px">
                      {{ advanced ? '收起' : '展开' }}
                      <a-icon :type="advanced ? 'up' : 'down'" />
                    </a>
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
          <!-- 操作 -->
          <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
            <div class="table-operations">
              <a-button
                type="primary"
                @click="$refs.createForm.handleAdd()"
                v-hasPermi="['tenant:iot:primitiveManage:add']"
              >
                <a-icon type="plus" />新增
              </a-button>
              <a-button
                type="primary"
                :disabled="single"
                @click="$refs.createForm.handleUpdate(undefined, ids)"
                v-hasPermi="['tenant:iot:primitiveManage:edit']"
              >
                <a-icon type="edit" />修改
              </a-button>
              <a-button
                type="danger"
                :disabled="multiple"
                @click="handleDelete"
                v-hasPermi="['tenant:iot:primitiveManage:remove']"
              >
                <a-icon type="delete" />删除
              </a-button>
              <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:iot:primitiveManage:export']">
                <a-icon type="download" />导出
              </a-button>
              <!-- <a-button
              type="dashed"
              shape="circle"
              :loading="loading"
              :style="{float: 'right'}"
              icon="reload"
              @click="getList" /> -->
            </div>
          </a-toolbar>
          <!-- 增加修改 -->
          <create-form
            ref="createForm"
            :deptOptions="deptOptions"
            :statusOptions="statusOptions"
            :colorOptions="colorOptions"
            @ok="getList"
          />
          <!-- 数据展示 -->
          <a-table
            :loading="loading"
            :size="tableSize"
            rowKey="id"
            tid="1"
            :columns="columns"
            :data-source="list"
            :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
            :pagination="false"
          >
            <span slot="status" slot-scope="text, record">
              {{ statusFormat(record) }}
            </span>
            <span slot="primitiveColor" slot-scope="text, record">
              {{ colorFormat(record) }}
            </span>
            <span slot="operation" slot-scope="text, record">
              <a-divider type="vertical" v-hasPermi="['tenant:iot:primitiveManage:edit']" />
              <a
                @click="$refs.createForm.handleUpdate(record, undefined)"
                v-hasPermi="['tenant:iot:primitiveManage:edit']"
              >
                <a-icon type="edit" />修改
              </a>
              <a-divider type="vertical" v-hasPermi="['tenant:iot:primitiveManage:remove']" />
              <a @click="handleDelete(record)" v-hasPermi="['tenant:iot:primitiveManage:remove']">
                <a-icon type="delete" />删除
              </a>
            </span>
          </a-table>
          <!-- 分页 -->
          <a-pagination
            class="ant-table-pagination"
            show-size-changer
            show-quick-jumper
            :current="queryParam.pageNum"
            :total="total"
            :page-size="queryParam.pageSize"
            :showTotal="(total) => `共 ${total} 条`"
            @showSizeChange="onShowSizeChange"
            @change="changeSize"
          />
        </a-col>
      </a-row>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listPrimitiveManage, delPrimitiveManage, exportPrimitiveManage } from '@/api/iot/primitiveManage'
import { treeselect } from '@/api/system/dept'
import CreateForm from './modules/CreateForm'
import DeptTree from './modules/DeptTree'

export default {
  name: 'PrimitiveManage',
  components: {
    CreateForm,
    DeptTree
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 机构树选项
      deptOptions: [
        {
          id: 0,
          label: '',
          children: []
        }
      ],
      // 是否删除字典
      statusOptions: [],
      // 图元颜色
      colorOptions: [],
      // 查询参数
      queryParam: {
        primitiveColor: null,
        status: null,
        pageNum: 1,
        pageSize: 10,
        deptId: undefined
      },
      columns: [
        {
          title: '图元类型',
          dataIndex: 'typeName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '图元颜色',
          dataIndex: 'primitiveColor',
          scopedSlots: { customRender: 'primitiveColor' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '所属机构',
          dataIndex: 'deptName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '是否删除',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
    this.getTreeselect()
    this.getDicts('iot_del_yesno').then((response) => {
      this.statusOptions = response.data
    })
    this.getDicts('iot_primitive_color').then((response) => {
      this.colorOptions = response.data
    })
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询图元管理列表 */
    getList() {
      this.loading = true
      listPrimitiveManage(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    // 是否删除字典翻译
    statusFormat(row, column) {
      return this.selectDictLabel(this.statusOptions, row.status)
    },
    // 图元颜色
    colorFormat(row, column) {
      return this.selectDictLabel(this.colorOptions, row.primitiveColor)
    },
    /** 查询机构下拉树结构 */
    getTreeselect() {
      treeselect().then((response) => {
        this.deptOptions = response.data
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.queryParam = {
        primitiveColor: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    clickDeptNode(deptId) {
      this.queryParam.deptId = deptId
      this.getList()
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delPrimitiveManage(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportPrimitiveManage(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
